import { PromotionType } from 'shared-ui/src/types/promotion';
import { BRAND_PROMO, RETAILER_PROMO } from '../react-components/promo/constants';
import { renderModal as renderGeneralPromoModal } from '../react-components/promo/modals/general-promo-modal';
import { LDP_METRICS_MANUFACTURER_CLASSNAME, LDP_METRICS_PROFILE_PAGE } from '../constants';
import { renderPromoModal } from '../react-components/PromoModal';

const findRebatePromotionID = (promotions) => {
  const rebatePromotion = promotions.find(
    (promotion) =>
      promotion.promotion_type === PromotionType.AFFILIATE_REBATE ||
      promotion.promotion_type === PromotionType.LOCAL_REBATE,
  );

  return rebatePromotion ? rebatePromotion.id : undefined;
};

export default function handleDealCouponSeeDeal(deal) {
  const currentDeal = deal.deals[0];
  const mainPromotionType = deal.deals[0].main_promotion_type;
  const isBrandPromo = mainPromotionType === PromotionType.BRAND;

  if (mainPromotionType === PromotionType.ONLINE) {
    renderPromoModal(deal);
  } else {
    renderGeneralPromoModal({
      type: isBrandPromo ? BRAND_PROMO : RETAILER_PROMO,
      storeSlug: currentDeal ? currentDeal.retailer.slug : undefined,
      storeName: currentDeal ? currentDeal.retailer.name : undefined,
      retailerSlug: currentDeal ? currentDeal.retailer.slug : undefined,
      retailerName: currentDeal ? currentDeal.retailer.name : undefined,
      productName: currentDeal ? deal.brand.name : undefined,
      title: currentDeal ? currentDeal.promotions[0].title : undefined,
      titleShort: currentDeal ? currentDeal.promotions[0].title_short : undefined,
      description: currentDeal ? currentDeal.promotions[0].description : undefined,
      promotionID: currentDeal ? findRebatePromotionID(currentDeal.promotions) : undefined,
      logo: isBrandPromo ? deal.brand.logo : currentDeal.retailer.logo,
      locationPage: LDP_METRICS_PROFILE_PAGE,
      objectClassname: LDP_METRICS_MANUFACTURER_CLASSNAME,
      dismiss: true,
    });
  }
}
