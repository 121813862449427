/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import has from 'lodash/has';
import { Brand } from '../types/brands';
import { BrandDeal, Deal, ProductDealResponse, RetailerDeal } from '../types/deal';
import { MattressModel } from '../types/mattresses';
import { NewPromotion, PayoutType, PromotionType } from '../types/promotion';
import fetch from './fetchService';
import { scrollToHeight } from './commons';
import { MANUFACTURER, ROUTES } from '../constants/commons';
import { getAnchorFormBaseUrl } from './urls';

export const BRAND_PARAM = 'brand';
export const SCROLL_TO_SAVE_DEAL_PARAM = 'to_save_deal';

export const getRebatePromoPayout = (percentage: number, fixedAmount: number, payoutType: PayoutType): string => {
  if (!percentage && !fixedAmount) return '';

  switch (payoutType) {
    case PayoutType.PERCENTAGE:
      return `${percentage}% Back`;
    case PayoutType.FIXED:
      return `$${fixedAmount} Back`;
    default:
      return '';
  }
};

export const getIsOnlyOnlinePromotionFromDeal = (deal?: Deal): boolean => {
  if (!deal) return false;

  const dealsPromotions = deal.deals[0].promotions;

  return (
    deal.deals.length === 1 &&
    dealsPromotions.length === 1 &&
    dealsPromotions.findIndex((d) => d.promotion_type === PromotionType.ONLINE) > -1
  );
};

export const getIsOnlyRebatePromotionFromDeal = (deal?: Deal): boolean => {
  if (!deal) return false;

  const dealsPromotions = deal.deals[0].promotions;

  return (
    deal.deals.length === 1 &&
    dealsPromotions.length === 1 &&
    dealsPromotions.findIndex(
      (d) =>
        d.promotion_type === PromotionType.AFFILIATE_REBATE ||
        d.promotion_type === PromotionType.LOCAL_REBATE ||
        d.promotion_type === PromotionType.REBATE,
    ) > -1
  );
};

export const getIsLocalRebatePromotionFromSingleDeal = (deal?: Deal): boolean => {
  if (!deal) return false;

  return deal.deals.length === 1 && deal.deals[0].main_promotion_type === PromotionType.LOCAL_REBATE;
};

export const isMultiDeal = (deal?: Deal | ProductDealResponse): boolean =>
  deal && deal.deals ? deal.deals.length > 1 : false;

export const getPromotionsFromDeal = (
  dealPromotions: NewPromotion[],
  skip = false,
): { onlinePromotion?: NewPromotion; rebatePromotion?: NewPromotion; generalPromotion?: NewPromotion } => {
  if (skip || (dealPromotions && dealPromotions.length === 0))
    return {
      onlinePromotion: undefined,
      rebatePromotion: undefined,
      generalPromotion: undefined,
    };

  return {
    onlinePromotion: dealPromotions.find((p) => p.promotion_type === PromotionType.ONLINE) || undefined,
    rebatePromotion:
      dealPromotions.find(
        (p) =>
          p.promotion_type === PromotionType.AFFILIATE_REBATE ||
          p.promotion_type === PromotionType.LOCAL_REBATE ||
          p.promotion_type === PromotionType.REBATE,
      ) || undefined,
    generalPromotion: dealPromotions.find(
      (p) => p.promotion_type === PromotionType.RETAILER || p.promotion_type === PromotionType.BRAND,
    ),
  };
};

export const formatProductToDeal = (product: Brand | MattressModel): Deal => {
  const promotion = product.extra_data.cashback_promotion || product.extra_data.promotion!;
  const brand: BrandDeal = {
    id: product.id,
    slug: product.slug,
    name: product.classname === MANUFACTURER ? (product as Brand).name : (product as MattressModel).manufacturer!.name,
    classname: product.classname,
    listed: product.listed || true,
    logo: product.logo,
    get_status_display: product.get_status_display || 'Active',
  };
  const retailer: RetailerDeal = {
    id: promotion.retailer.id,
    slug: promotion.retailer.slug,
    name: promotion.retailer.name,
    logo: promotion.retailer.logo || '',
  };
  const promotions: NewPromotion[] = [];
  let description = '';

  if (product.extra_data.cashback_promotion) {
    const cashback = product.extra_data.cashback_promotion;
    promotions.push(cashback);
    description =
      cashback && cashback.payout_type === PayoutType.FIXED
        ? `$${cashback.fixed_payout_amount}`
        : `${cashback.percentage_payout}%`;
  }
  if (product.extra_data.promotion) promotions.push(product.extra_data.promotion);

  return {
    brand,
    deals: [
      {
        description,
        main_promotion_type: promotion.promotion_type,
        brand,
        retailer,
        promotions,
      },
    ],
  };
};

export const hasPromotion = (product: Brand | MattressModel): boolean => {
  return (
    !!product.extra_data.cashback_promotion || !!product.extra_data.general_promotion || !!product.extra_data.promotion
  );
};

export const getBrandFromDeal = (deal: Deal): Brand => {
  const onlinePromotion = deal.deals[0].promotions.find((p) => p.promotion_type === PromotionType.ONLINE) || undefined;
  const generalPromotion =
    deal.deals[0].promotions.find(
      (p) => p.promotion_type === PromotionType.RETAILER || p.promotion_type === PromotionType.BRAND,
    ) || undefined;

  const cashbackPromotion =
    deal.deals[0].promotions.find(
      (p) =>
        p.promotion_type === PromotionType.AFFILIATE_REBATE ||
        p.promotion_type === PromotionType.LOCAL_REBATE ||
        p.promotion_type === PromotionType.REBATE,
    ) || undefined;

  const product = {
    ...deal.brand,
    extra_data: {
      cashback_promotion: cashbackPromotion
        ? {
            ...cashbackPromotion,
            retailer: deal.deals[0].retailer,
          }
        : undefined,
      promotion: onlinePromotion
        ? {
            ...onlinePromotion,
            retailer: deal.deals[0].retailer,
          }
        : undefined,
      general_promotion: generalPromotion
        ? {
            ...generalPromotion,
            retailer: deal.deals[0].retailer,
          }
        : undefined,
    },
  };

  return product as Brand;
};

export const openCashbackOutboundUrl = (
  manufacturerId: number,
  rebatePromotion: NewPromotion,
  onlinePromotion?: NewPromotion,
): void => {
  const { protocol, host, pathname, search } = window.location;
  const prev = search.length > 0 ? `${pathname}${search}` : pathname;
  let url = `${protocol}//${host}${ROUTES.cashbackCode}${rebatePromotion.id}/?prev=${prev}`;

  if (manufacturerId) url += `&manufacturer=${manufacturerId}`;

  window.open(url, '_blank');

  window.location.href = onlinePromotion?.outbound_url || rebatePromotion?.outbound_url || '';
};

export const getPromoUrlWithAnchor = (baseUrl: string, promoUrl: string): string => {
  const anchor = getAnchorFormBaseUrl(baseUrl);

  if (isEmpty(anchor)) return promoUrl;

  return `${promoUrl.replace(anchor, '')}${anchor}`;
};

export const openProductDiscountOutboundUrl = (
  id: number,
  classname: string,
  slug: string,
  outboundURL?: string,
  cashBackPromoID?: number,
  isOnlyCashBack?: boolean,
): void => {
  let url = '';
  const baseUrl = window.location.href;
  const screenPos = Math.round(window.scrollY);

  let params = `show-promo-code=${id}&classname=${classname}&slug=${slug}&screen_pos=${screenPos}`;
  if (isOnlyCashBack) {
    params += `&is_cp=true`;
  } else if (cashBackPromoID) {
    params += `&cashback_promo=${cashBackPromoID}`;
  }

  if (baseUrl.indexOf('?') > -1) url = `${baseUrl}&${params}`;
  else url = `${baseUrl}?${params}`;

  window.open(getPromoUrlWithAnchor(baseUrl, url), '_blank');

  if (!isEmpty(outboundURL)) window.location.href = outboundURL as string;
};

export const openDealOutboundUrl = (deal: Deal, scrollToSaveDeal = false): void => {
  const multiDeal = isMultiDeal(deal);
  const isLocalRebate = getIsLocalRebatePromotionFromSingleDeal(deal);

  if (!multiDeal && !isLocalRebate) {
    const currentUrl = window.location.href;
    const screenPos = Math.round(window.scrollY);
    const isOnlyRebatePromotion = getIsOnlyRebatePromotionFromDeal(deal);
    let outboundURL = '';
    let url = '';

    if (isOnlyRebatePromotion) outboundURL = deal.deals[0].promotions[0].outbound_url;
    else {
      const onlinePromotion = deal.deals[0].promotions.find((p) => p.promotion_type === PromotionType.ONLINE);

      outboundURL = onlinePromotion?.outbound_url || '';
    }

    const urlParts = currentUrl.split('?');
    const baseUrl = urlParts[0];
    const currentQuery = urlParts[1] || '';
    const parsedQuery = qs.parse(currentQuery);

    parsedQuery[BRAND_PARAM] = `${deal.brand.id}`;
    parsedQuery.screen_pos = `${screenPos}`;

    if (scrollToSaveDeal) parsedQuery[SCROLL_TO_SAVE_DEAL_PARAM] = '1';

    const updatedQuery = qs.stringify(parsedQuery);

    url = `${baseUrl}?${updatedQuery}`;

    window.open(getPromoUrlWithAnchor(baseUrl, url), '_blank');

    if (!isEmpty(outboundURL)) window.location.href = outboundURL;
  }
};

const removeUrlParams = (): void => {
  const urlParts = window.location.href.split('?');
  const baseUrl = urlParts[0];
  const currentQuery = urlParts[1] || '';
  const parsedQuery = qs.parse(currentQuery);

  delete parsedQuery.brand;
  delete parsedQuery.screen_pos;

  const cleanUrl = `${baseUrl}${qs.stringify(parsedQuery, {
    addQueryPrefix: true,
  })}`;
  window.history.pushState({}, '', `${cleanUrl}`);
};

export const getDealFromUrlParams = (): Promise<Deal | null> => {
  const params = qs.parse(window.location.search.slice(1));

  const hasParams = has(params, BRAND_PARAM);
  const screenHeight = Number(params.screen_pos);

  if (screenHeight) {
    scrollToHeight(screenHeight);
  }

  if (hasParams) {
    return fetch.get<Deal>(`/promos/brand-deals/${params.brand}/`).then((response) => {
      removeUrlParams();
      return response;
    });
  }

  return Promise.resolve(null);
};

export const shouldShowIncognitoInfo = (promo: NewPromotion | undefined): boolean => {
  return promo?.promotion_type !== PromotionType.LOCAL_REBATE && promo?.should_hide_incognito_instructions === true;
};

export const shouldRequiredPromoCode = (promo: NewPromotion | undefined): boolean => {
  return promo?.promotion_type !== PromotionType.LOCAL_REBATE && promo?.promo_code_is_required === true;
};

export const getSeasonDiscountText = (currentDate: Date): string => {
  const seasons = ['Winter', 'Spring', 'Summer', 'Fall'];

  const currentMonth = currentDate.getMonth() + 1;
  const currentSeason = seasons[Math.floor((currentMonth % 12) / 3)];

  return `${currentSeason} Savings`;
};
