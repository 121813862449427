import React, { FC } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import cls from 'classnames';
import { User } from '../../../types/user';
import { Deal, DealCouponVariantType, DealItem } from '../../../types/deal';
import { NewPromotion, PromotionType } from '../../../types/promotion';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import { getPromotionsFromDeal, openCashbackOutboundUrl } from '../../../utils/promos';
import NewPromoButton from '../../atoms/NewPromoButton';
import styles from './DealCoupon.module.scss';
import Button from '../../atoms/Button';
import { ROUTES } from '../../../constants/commons';
import PostHogService from '../../../utils/posthogService';
import { PostHogExperimentEvents } from '../../../constants/feature-flags';

export const DEAL_COUPON_ID = 'deal-coupon';

export enum DealCouponLayoutType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export const goToCashback = (
  rebate: NewPromotion,
  deal?: Deal,
  dealHash?: string,
  trackLocalDealPostHogExperiment?: boolean,
): void => {
  const { protocol, host, pathname, search } = window.location;
  const prev = search.length > 0 ? `${pathname}${search}` : pathname;

  let url = `${protocol}//${host}${ROUTES.cashbackCode}${rebate.id}/?prev=${prev}&experiment=${trackLocalDealPostHogExperiment}`;
  if (deal?.brand.id) url += `&manufacturer=${deal.brand.id}`;
  if (dealHash) url += `&deal=${dealHash}`;

  window.location.href = url;
};

export const handleDealClick = (
  deal: Deal,
  currentDeal: DealItem,
  posthog: any,
  trackLocalDealPostHogExperiment: boolean,
  onSeeDeal?: (deal: Deal) => void,
): void => {
  if (currentDeal) {
    const { onlinePromotion, rebatePromotion } = getPromotionsFromDeal(currentDeal.promotions);

    if (onSeeDeal && deal) onSeeDeal(deal);

    if (currentDeal.main_promotion_type === PromotionType.AFFILIATE_REBATE && deal) {
      if (rebatePromotion) {
        openCashbackOutboundUrl(deal.brand.id, rebatePromotion, onlinePromotion);
      }
    } else if (
      (currentDeal.main_promotion_type === PromotionType.LOCAL_REBATE ||
        currentDeal.main_promotion_type === PromotionType.REBATE) &&
      rebatePromotion
    ) {
      if (trackLocalDealPostHogExperiment) posthog.capture(PostHogExperimentEvents.LOCAL_REBATE_BEST_DEAL_CLICKED);

      goToCashback(rebatePromotion, deal, currentDeal.hash, trackLocalDealPostHogExperiment);
    }
  }
};

export type DealCouponProps = {
  pageRegion: PageRegion;
  user: User | null;
  className?: string;
  layout?: DealCouponLayoutType;
  variant?: DealCouponVariantType;
  deal?: Deal;
  isFetching?: boolean;
  showHeader?: boolean; // hide title and subtitle
  showExtraCashbackText?: boolean;
  inlinePromotionText?: boolean;
  inlineGoodbedText?: boolean;
  hideSeeDeal?: boolean;
  hideNewPromoBtnSubtitle?: boolean;
  // This is used for PostHogFeatureFlags.SIDEBAR_WIDTH_PROFILE_PAGE. Can be removed after experiment is done.
  trackLocalDealPostHogExperiment?: boolean;
  onSeeDeal?: (deal: Deal) => void;
};

const getTitle = (currentDeal?: DealItem): string => {
  if (!currentDeal) return '';

  return currentDeal.retailer.name;
};

const DealCoupon: FC<DealCouponProps> = ({
  pageRegion,
  user,
  className = '',
  layout = DealCouponLayoutType.HORIZONTAL,
  variant = DealCouponVariantType.DEFAULT,
  deal,
  isFetching = false,
  showHeader = true,
  showExtraCashbackText = true,
  inlinePromotionText = false,
  inlineGoodbedText = false,
  hideSeeDeal = false,
  hideNewPromoBtnSubtitle = false,
  trackLocalDealPostHogExperiment = false,
  onSeeDeal,
}) => {
  const posthog = new PostHogService(user as User);
  const currentDeal = isFetching ? undefined : deal?.deals[0];
  const promotionType = currentDeal ? currentDeal.main_promotion_type : undefined;
  const isAffiliateRebate = promotionType === PromotionType.AFFILIATE_REBATE;
  const isLocalRebate = promotionType === PromotionType.LOCAL_REBATE;
  const isBrandOrRetailerPromotion =
    currentDeal &&
    (currentDeal.main_promotion_type === PromotionType.BRAND ||
      currentDeal.main_promotion_type === PromotionType.RETAILER);
  const hasMultiplePromotions = currentDeal ? currentDeal.promotions.length > 1 : false;
  const noSeeDealBtn = isBrandOrRetailerPromotion && hideSeeDeal;
  const title = getTitle(currentDeal);
  const ctaData = {
    category: hasMultiplePromotions ? CTAClickCategory.CASHBACK_PROMOTION : CTAClickCategory.ONLINE_PROMOTION,
    level: CTAClickLevel.PRIMARY,
    url: '/',
    pageRegion,
    product:
      currentDeal && currentDeal.brand
        ? getProductEventDataWithMattressHierarchy(currentDeal.brand)
        : EMPTY_EVENT_PRODUCT,
  };

  const getPromotionText = (): JSX.Element => {
    return (
      <ReactPlaceholder
        type="rect"
        style={{
          width: layout === DealCouponLayoutType.HORIZONTAL ? '70%' : '80%',
          height: '100px',
          marginBottom: '10px',
        }}
        ready={!isFetching}
      >
        <div className={`${styles.promotions} promotions`}>
          {/* MULTIPLE PROMOTIONS AND IT IS ONLINE, BRAND OR RETAILER PROMOTION */}
          {currentDeal &&
            hasMultiplePromotions &&
            (currentDeal.promotions[1].promotion_type === PromotionType.ONLINE ||
              currentDeal.promotions[1].promotion_type === PromotionType.BRAND ||
              currentDeal.promotions[1].promotion_type === PromotionType.RETAILER) && (
              <b>{currentDeal.promotions[1].title_short}</b>
            )}

          {/* IT IS ONLY ONE ONLINE, BRAND OR RETAILER PROMOTION */}
          {currentDeal &&
            (currentDeal.promotions[0].promotion_type === PromotionType.ONLINE ||
              currentDeal.promotions[0].promotion_type === PromotionType.BRAND ||
              currentDeal.promotions[0].promotion_type === PromotionType.RETAILER) && (
              <b>{currentDeal.promotions[0].title_short}</b>
            )}

          {/* DEAL MAIN PROMOTION TYPE IS BRAND OR RETAILER */}
          {!hasMultiplePromotions && isBrandOrRetailerPromotion && <b>{currentDeal.promotions[0].title_short}</b>}

          {!inlinePromotionText && hasMultiplePromotions && <br />}

          {hasMultiplePromotions && ' + '}

          {/* DEAL MAIN PROMOTION TYPE IS LOCAL REBATE OR AFFILIATE REBATE */}
          {currentDeal && (isAffiliateRebate || isLocalRebate || promotionType === PromotionType.REBATE) && (
            <>
              <b>{currentDeal.promotions[0].title_short} </b>
              {!inlinePromotionText && !inlineGoodbedText && <br />}
              <span className={styles.promotionsSmallText}>from GoodBed </span>
            </>
          )}
        </div>
      </ReactPlaceholder>
    );
  };

  const handlePromoBtnClick = (): void => {
    // This is used for PostHogFeatureFlags.SIDEBAR_WIDTH_PROFILE_PAGE. Can be removed after experiment is done.
    if (isLocalRebate && trackLocalDealPostHogExperiment)
      posthog.capture(PostHogExperimentEvents.LOCAL_REBATE_BEST_DEAL_CLICKED);
  };

  const getPromotionBtn = (): JSX.Element => {
    return (
      <ReactPlaceholder
        type="rect"
        style={{
          width: layout === DealCouponLayoutType.HORIZONTAL ? '22%' : '60%',
          height: '50px',
          marginBottom: '10px',
        }}
        ready={!isFetching}
      >
        {isBrandOrRetailerPromotion && !hideSeeDeal && (
          <Button
            className={styles.btnSeeDeal}
            ariaLabel="See Deal"
            size="lg"
            color="dark-blue"
            variant="outline-primary"
            ctaData={ctaData}
          >
            See Deal
          </Button>
        )}
        {!isBrandOrRetailerPromotion && currentDeal && (
          <NewPromoButton
            className={styles.btnNewPromotion}
            promotionType={currentDeal?.main_promotion_type}
            mfrName={currentDeal.brand.name}
            retailerName={currentDeal.retailer.name}
            ctaData={ctaData}
            hideSubtitle={hideNewPromoBtnSubtitle}
            onClick={handlePromoBtnClick}
          />
        )}
      </ReactPlaceholder>
    );
  };

  const getExtraCashBackText = (): JSX.Element => {
    return (
      <ReactPlaceholder type="text" style={{ marginBottom: '5px' }} rows={2} ready={!isFetching}>
        <div className={`${styles.extra} extra`}>
          Get an{' '}
          <u>
            <b>extra {currentDeal?.description} cash back</b>
          </u>{' '}
          from GoodBed if you buy {isAffiliateRebate ? `online from ${title}` : `at a ${title} store`}.
        </div>
      </ReactPlaceholder>
    );
  };

  const variantsMap = {
    blue: styles.blueDealCoupon,
    default: styles.defaultDealCoupon,
  };

  return (
    <a
      id={DEAL_COUPON_ID}
      data-testid={DEAL_COUPON_ID}
      className={`${styles.container} ${styles[layout] || ''} ${noSeeDealBtn ? styles.noSeeDealBtn : ''} ${className} ${
        variantsMap[variant] || ''
      }`}
      role="button"
      tabIndex={-1}
      rel="nofollow"
      onClick={
        isFetching
          ? undefined
          : (): void => {
              if (deal && currentDeal)
                handleDealClick(deal, currentDeal, posthog, trackLocalDealPostHogExperiment, onSeeDeal);
            }
      }
    >
      {showHeader ? (
        <>
          <ReactPlaceholder type="text" style={{ width: '50%', marginBottom: '10px' }} rows={1} ready={!isFetching}>
            <div className={styles.title}>{title}</div>
          </ReactPlaceholder>
          {layout === DealCouponLayoutType.VERTICAL && (
            <ReactPlaceholder type="text" style={{ width: '30%', marginBottom: '10px' }} rows={1} ready={!isFetching}>
              <div className={styles.subtitle}>
                {isAffiliateRebate ? 'Online Seller' : null}
                {!isAffiliateRebate && currentDeal?.retailer.nearest_store ? (
                  <span>
                    {currentDeal?.retailer.nearest_store.city}, {currentDeal?.retailer.nearest_store.state_abbr}
                  </span>
                ) : null}
              </div>
            </ReactPlaceholder>
          )}
        </>
      ) : null}
      {layout === DealCouponLayoutType.HORIZONTAL ? (
        <>
          {showExtraCashbackText && !isBrandOrRetailerPromotion && getExtraCashBackText()}
          <div
            className={cls(
              `row align-items-center no-gutters g-0 ${noSeeDealBtn ? 'justify-content-center' : ''}`,
              styles.horizontalContainer,
            )}
          >
            {getPromotionBtn()}
            {getPromotionText()}
          </div>
        </>
      ) : (
        <>
          {getPromotionText()}
          {getPromotionBtn()}
          {showExtraCashbackText && !isBrandOrRetailerPromotion && getExtraCashBackText()}
        </>
      )}
    </a>
  );
};

export default DealCoupon;
